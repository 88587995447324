import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { OrangeButton } from "../components/button-orange"

const NotFoundPage = (props) => {
  return (
    <Layout>
      <Seo
        title="404: Page Not Found - Iron West Group Affordable Heavy Equipment Sales"
        description="You've reached a page that does not exist. Please try again at a later time!"
        location={props.location.pathname}
      />
      <div className="bg-lightgray py-16 px-5 lg:px-10">
        <div className="container mx-auto  text-center">
          <h3 className="font-bold text-3xl pb-3">Page Not Found</h3>
          <p className="pb-5">
            You've reached a page that does not exist.
          </p>
          <OrangeButton buttonPage="/" buttonText="Return Home" buttonTitle='Go To Iron West Group Home Page' buttonAlt='Iron West Group - Home Page' />
        </div>
      </div>
    </Layout>
  )
}
export default NotFoundPage